import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  id = input<string>();
  startIcon = input<{ src: string; alt: string }>();
  endIcon = input<{ src: string; alt: string }>();
  label = input<string>();
  badgeClass = input.required<string>();
}
