@if (isShownAlert) {
<div
  [class]="inlineClasses()"
  [class]="styles[style()]"
  class="alert-sticky at-flex at-justify-between at-align-center at-py-md at-px-4xl at-shadow-xs">
  <div class="at-flex">
    @if (icon()) {
    <div class="icon at-me-sm">
      <img [src]="icon()" alt="icon" width="20" height="20" />
    </div>
    }
    <div class="info at-fw-medium">
      {{ message() }}
    </div>
  </div>

  <div class="at-flex">
    <div class="actions">
      <ng-content />
    </div>
    <button class="at-ms-sm" type="button" (click)="closeAlert()">
      @if (style() === 'primary1') {
      <img [src]="'assets/icons/close/white.svg'" alt="close icon" />
      } @else if (style() === 'primary2') {
      <img [src]="'assets/icons/close/primary.svg'" alt="close icon" />
      } @else {
      <img [src]="'assets/icons/close/black.svg'" alt="close icon" />
      }
    </button>
  </div>
</div>
}
