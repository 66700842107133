<div
  class="stat_card at-w-100 at-flex at-justify-between at-align-center at-p-2xl at-shadow-lg at-radius-md at-bg-white">
  <diV class="stat_card__header at-flex at-align-center">
    @if (icon()) {
    <span class="stat-icon-container at-me-sm at-bg-primary-50 at-radius-sm">
      <img [src]="icon()" class="stat-icon" alt="stat-icon" />
    </span>
    }
    <div class="stat-title-container">
      @if(overline()){
      <p class="at-text-sm at-fw-medium">{{ overline() }}</p>
      }
      <h3 class="at-h3 at-fw-semibold at-mt-x-sm">{{ lable() }}</h3>
      @if (description()) {
      <p class="at-text-sm at-fw-medium">{{ description() }}</p>
      }
    </div>
  </diV>
  <div class="stat-tag-container">
    @if (tag()) {
    <p
      class="tag at-text-success-700 at-text-sm at-text-success-50 at-bg-success-50 at-radius-xxxl at-py-x-sm at-px-sm">
      <span style="font-size: 16px">&#8593;</span> {{ tag() }} %
    </p>
    }
  </div>
</div>
