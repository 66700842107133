@if (childControl.errors && childControl.dirty && childControl.touched) {
<ul class="at-text-destructive-500 at-text-sm at-fw-normal at-mt-sm">
  @for ( error of childControl.errors | keyvalue; track $index; let first =
  $first ) { @if (first) {
  <li>
    @if (errorMessages) { @if (errorMessages[error.key]) {
    {{ errorMessages[error.key] }}
    } @else {
    {{ label() + ' is not valid' }}
    } }
  </li>
  } }
</ul>
}
