<nav class="at-w-100 at-flex at-justify-between at-py-lg at-px-6xl at-bg-white">
  <a
    class="at-no-decoration at-flex at-align-center"
    id="logo"
    [routerLink]="'/'">
    <span class="at-h6 at-text-neutral-900 at-fw-bold"> DIB </span>
  </a>

  <div>
    @if (hasNotification()) {
    <a>
      <img src="assets/images/icons/notification.svg" alt="notification" />
    </a>
    }
  </div>
</nav>
