import { NgClass } from '@angular/common';
import { AfterViewInit, Component, inject, input, output } from '@angular/core';
import { RouterModule, Router } from '@angular/router';

export interface MenuItem {
  defaultIcon?: string;
  activeIcon?: string;
  label: string;
  path?: string;
  action?: string;
  expanded?: boolean;
  children?: MenuItem[];
  active?: boolean;
}

@Component({
  selector: 'lib-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  imports: [RouterModule, NgClass],
  standalone: true,
})
export class SidenavComponent implements AfterViewInit {
  isCollapsed = false;
  menuItems = input.required<MenuItem[]>();
  footerItems = input<MenuItem[]>([]);
  onAction = output<string>();
  router = inject(Router);
  icon?: string;

  ngAfterViewInit(): void {
    const currentRoute = this.router.url;
    this.menuItems().forEach(item => {
      if (
        item.path === currentRoute ||
        (item.path && currentRoute.includes(item.path))
      ) {
        item.active = true;
      }
    });
  }

  toggleSidenav() {
    this.isCollapsed = !this.isCollapsed;
    this.menuItems().forEach(item => {
      if (item.children && item.children.length > 0) {
        item.expanded = false;
      }
    });
  }

  toggleSubMenu(item: MenuItem) {
    if (item.children && item.children.length > 0) {
      item.expanded = !item.expanded;
    }
    if (item.action) {
      this.onAction.emit(item.action);
    }
  }

  onClickMenuItem() {
    this.menuItems().forEach(item => {
      item.active = true;
    });
  }

  mouseenter(index: number, menuLabel?: string) {
    if (menuLabel == 'Sign out') {
      this.icon = this.footerItems()[index].defaultIcon;
      this.footerItems()[index].defaultIcon =
        this.footerItems()[index].activeIcon;
    } else {
      this.icon = this.menuItems()[index].defaultIcon;
      this.menuItems()[index].defaultIcon = this.menuItems()[index].activeIcon;
    }
  }

  mouseleave(index: number, menuLabel?: string) {
    if (menuLabel == 'Sign out') {
      this.footerItems()[index].defaultIcon = this.icon;
    } else {
      this.menuItems()[index].defaultIcon = this.icon;
    }
  }
}
