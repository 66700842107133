import { Component, input, model, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DocumentFile } from './upload-documents.interface';

@Component({
  selector: 'lib-upload-documents',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './upload-documents.component.html',
  styleUrl: './upload-documents.component.scss',
})
export class UploadDocumentsComponent {
  formGroup = input.required<FormGroup>();
  inputFormControlName = input.required<string>();
  id = input<string>('');
  name = input<string>();
  inputClass = input<string>('');
  label = input<string>();
  disabled = input<boolean>();
  readonly = input<boolean>();
  value = input<string>('');
  required = input<boolean>();
  direction = input<'rtl' | 'ltr'>();
  errorMessage = model<string>();
  hintMessage = input<string>();
  accept = input<string>();
  fileSizeLimit = input<number>(1000000); // 1 mega
  uploadedFileLimit = input<number>(5);
  documentList = input<DocumentFile[]>([]);
  isEditMode = input<boolean>();
  delete = output<number>();
  isDraggedIn!: boolean;
  seletedDocumentId!: number;

  controlName() {
    return (
      this.formGroup() && this.formGroup().controls[this.inputFormControlName()]
    );
  }

  downloadFile(url: unknown) {
    if (url) {
      window.open(url as string, '_blank');
    }
  }

  onDocumentChange(event: Event): void {
    if (event.target) {
      const files: File[] = (<HTMLInputElement>event.target)
        .files as unknown as File[];
      const file: File = files[0];
      if (file) {
        switch (true) {
          case file.size > this.fileSizeLimit(): {
            this.errorMessage.set(
              `File size must be less than ${
                this.fileSizeLimit() / 1000000
              } mega`
            );
            break;
          }

          case !file.type.includes('pdf') && !file.type.includes('image'): {
            this.errorMessage.set(`File format uploaded is invalid`);
            break;
          }

          case this.documentList().length === this.uploadedFileLimit(): {
            this.errorMessage.set(
              `Files count can not be more than ${this.uploadedFileLimit()} files`
            );
            break;
          }

          default: {
            const fileName: string = file.name.split('.')[0];
            const document: DocumentFile = {
              name: fileName,
              file: file,
            };
            this.documentList().push(document);

            this.checkOnValidators();
            this.clearErrorMessage();
            break;
          }
        }
      }

      this.checkOnValidators();
    }
  }

  removeFile(i: number) {
    if (this.documentList() && this.documentList().length) {
      this.removeDocumentIdFromDocumentList(i);
      this.documentList().splice(i, 1);
      this.controlName().setValue('');
      this.checkOnValidators();
    }
  }

  removeDocumentIdFromDocumentList(i: number) {
    if (this.isEditMode() && this.documentList()[i].id) {
      this.seletedDocumentId = this.documentList()[i].id as number;
      this.delete.emit(this.seletedDocumentId);
    }
  }

  checkOnValidators() {
    if (this.required() && !this.documentList().length) {
      this.controlName().setErrors({ required: true });
    } else {
      this.controlName().setErrors(null);
    }
    const inputId = <HTMLInputElement>document.getElementById(this.id());
    if (inputId) {
      inputId.value = '';
    }
    this.controlName().markAsTouched();
    this.controlName().updateValueAndValidity();
  }

  clearErrorMessage() {
    this.errorMessage.set('');
    this.controlName().setErrors(null);
    this.controlName().updateValueAndValidity();
  }
}
