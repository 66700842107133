<div
  class="badge at-justify-center at-align-center at-radius-xxxl at-border"
  [ngClass]="badgeClass()">
  @if (startIcon()) {
  <img [src]="startIcon()?.src" [alt]="startIcon()?.alt" />
  }

  <span [ngClass]="{ 'at-ps-sm': startIcon(), 'at-pe-sm': endIcon() }">
    {{ label() }}
  </span>

  @if (endIcon()) {
  <img [src]="endIcon()?.src" [alt]="endIcon()?.alt" />
  }
</div>
