import { Component, input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from './modal.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  modal = input<Modal>({
    isOpen: false,
    hasCloseIcon: false,
    hasLikeIcon: false,
    title: '',
    description: '',
    customizeContent: {} as TemplateRef<unknown>,
  });

  closeModal() {
    this.modal().isOpen = false;
    if (this.modal().formGroup) {
      (<FormGroup>this.modal().formGroup).reset();
    }
  }
}
